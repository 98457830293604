import { useTranslation } from "react-i18next"
import { useAsync } from "react-use"
import { useQueryString } from "./useQueryString"

export const useLang = (defaultLang = 'vi') => {
  const { i18n } = useTranslation()
  const qs = useQueryString()
  const lang = qs.lang ?? defaultLang

  useAsync(async () => {
    await i18n.changeLanguage(lang)
  }, [lang, i18n])

  return lang
}

import { useEffect, useState } from "react"
import dsBridge from "dsbridge"
import { useQueryString } from "./useQueryString"

export const useToken = () => {
  const [token, setToken] = useState("")
  const qs = useQueryString()

  useEffect(() => {
    if (qs?.token) {
      setToken(qs.token)
    } else {
      dsBridge.call("getToken", "getToken", function(v) {
        setToken(v.token)
      })
    }
  }, [qs])

  return token
}

import React, { ChangeEvent } from "react"
import { CloseBoxIcon, SearchIcon } from "../assets"

export type BoxInputProps = {
  onChange(e: ChangeEvent<HTMLInputElement>): void
  onClear(): void
  value: string
}

export const BoxInput: React.FC<BoxInputProps> = ({
  onChange,
  onClear,
  value
}) => {
  return (
    <div className={"container"}>
      <div className={"icon"}>
        <SearchIcon />
      </div>
      <input
        value={value}
        type="text"
        className={"input"}
        placeholder={"Enter a address"}
        onChange={onChange}
      />
      {!!value && (
        <div className={"icon"} onClick={onClear}>
          <CloseBoxIcon />
        </div>
      )}

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          height 54px
          background #fff
          display flex
        }
        
        .input {
          flex 1
          font-size 16px
          line-height 24px
          border none
          outline none
        }

        .icon {
          padding 16px
        }
      `}</style>
    </div>
  )
}

import React, { useCallback, useMemo, useState } from "react"
import { Header } from "../components/Header"
import { Process } from "../components/Process"
import { CashIcon, MarkerIcon } from "../assets"
import { Button } from "../components/Button"
import { useAsync } from "react-use"
import axios from "axios"
import dayjs from "dayjs"
import { Alert } from "../components/Alert"
import { useHistory } from "react-router-dom"
import { ConfirmAlert } from "../components/ConfirmAlert"
import { Loading } from "../components/Loading"
import { useTranslation } from "react-i18next"
import { numberFormat } from "../functions/format"
import Ripples from "react-ripples"
import { Photo } from "../interfaces"
import store from "../store"

export interface Location {
  type: string
  coordinates: number[]
}

export interface Merchant {
  _id: string
  name: string
  service_id: string
  address: string
  email: string
  enable: boolean
  short_address: string
  location: Location
}

export interface Estimated {
  print_photo_fee: number
  print_photo_time: number
  delivery_time: number
  estimated_time: number
  delivery_fee: number
  estimated_fee: number
  discount: number
  promo_error_message: string
  photos: Photo[]
  idle_util: number
}

type CardItemProps = {
  label: string
  numPhotos?: number
  price: number
}

const CardItem: React.FC<CardItemProps> = ({ label, numPhotos, price }) => {
  return (
    <div className={"card-item"}>
      <div className={"card-item__label"}>
        {numPhotos && <span>x{numPhotos}</span>}
        <span>{label}</span>
      </div>
      <span className={"card-item__price"}>đ{numberFormat.format(price)}</span>

      {/*language=Stylus*/}
      <style jsx>{`
        .card-item {
          margin-top 12px
          display flex
          justify-content space-between
          
          &__label {
            span:first-child {
              margin-right 8px
              font-weight bold
            }
          }
          
          &__price {
            color #FF8200
          }
        }
      `}</style>
    </div>
  )
}

export const CheckoutPage = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const photos = store.photos

  const [showAddAddrDetail, setShowAddAddrDetail] = useState(false)
  const [loading, setLoading] = useState(true)
  const [promotionCode, setPromotionCode] = useState("")
  const [showPromotionCode, setShowPromotionCode] = useState(false)
  const [shouldGoBack, setShouldGoBack] = useState(false)
  const [error, setError] = useState("")
  const [remarks, setRemarks] = useState("")
  const [photoLab, setPhotoLab] = useState<Merchant | null>(null)
  const [estimated, setEstimated] = useState<Estimated>({
    idle_util: 0,
    print_photo_fee: 0,
    print_photo_time: 0,
    delivery_time: 0,
    estimated_time: 0,
    delivery_fee: 0,
    estimated_fee: 0,
    discount: 0,
    promo_error_message: "",
    photos: []
  })
  const [disabled, setDisabled] = useState(true)

  const formattedTime = useMemo(
    () =>
      dayjs()
        .add(estimated.estimated_time, "m")
        .format("h:mmA, DD MMM YYYY"),
    [estimated.estimated_time]
  )

  const getPath = useCallback((photoLab, options: any = {}) => {
    return [
      {
        address: photoLab.address,
        lat: photoLab.location.coordinates[1],
        lng: photoLab.location.coordinates[0],
        mobile: photoLab._id,
        name: photoLab.name
      },
      {
        ...store.path,
        ...options
      }
    ]
  }, [])

  const estimate = useCallback(
    async (photoLab, promotionCode) => {
      const path = getPath(photoLab)

      return axios
        .post<Estimated>(`${process.env.REACT_APP_PHOTOLAB_URL}/estimated`, {
          token: store.token,
          service_id: photoLab.service_id,
          merchant_id: photoLab._id,
          print_photo_time: 30,
          path,
          photos: store.photos,
          promo_code: promotionCode
        })
        .then(v => v.data)
    },
    [getPath]
  )

  useAsync(async () => {
    try {
      setLoading(true)
      const photoLab = await axios
        .get<Merchant>(`${process.env.REACT_APP_PHOTOLAB_URL}/nearest`, {
          params: {
            lat: store.path.lat,
            lng: store.path.lng
          }
        })
        .then(v => v.data)

      if (!photoLab) {
        setLoading(false)
        setError(t('text.cant_find_nearest_photolab'))
        setShouldGoBack(true)
        setDisabled(true)
        return
      }

      const estimated = await estimate(photoLab, "")

      setEstimated(estimated)
      setPhotoLab(photoLab)
      setDisabled(false)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      if (e.response) {
        const respData = e.response.data
        if (respData.message) {
          if (respData.message.length > 0) {
            setError(t('text.please_refresh_your_app'))
          }
        }
        return
      }
      setError(e.message)
    }
  }, [])

  const onBook = useCallback(async () => {
    try {
      // if (!parsedQuery.token) {
      //   throw new Error('Missing user token')
      // }

      setLoading(true)

      if (!photoLab) {
        setError("Can not find any near photo lab")
        setLoading(false)
        return
      }

      if (!estimated) {
        setError("Something went wrong. Please try again")
        setLoading(false)
        return
      }

      // const totalPhotos = parseInt(parsedQuery.total_photos ?? "1")
      const resp = await axios
        .post<any>(`${process.env.REACT_APP_PHOTOLAB_URL}/create-order`, {
          token: store.token,
          path: getPath(photoLab, {
            cod: estimated.print_photo_fee,
            // cod_discrepancy: estimated.estimated_fee,
            remarks,
            visa_photo_url: store.visaImageUrl
          }),
          photos: photos.map(photo => ({
            total_images: photo.totalImages,
            visa_type: photo.visaType,
            size: photo.printingSize?.size,
            unit: photo.printingSize?.unit,
            image_url: `https://storage.googleapis.com/aha-photolab/${process.env.REACT_APP_STAGE}/${photo.filename}`,
          })),
          service_id: photoLab.service_id,
          payment_method: "CASH",
          merchant_id: photoLab._id,
          photo_type: store.photoType,
          total_pay: estimated.delivery_fee
        })
        .then(resp => resp.data)

      if (resp.message === 'An invalid response was received from the upstream server') {
        throw new Error(t('text.sth_went_wrong'))
      }

      await axios.post(`${process.env.REACT_APP_PHOTOLAB_URL}/notify`, {
        order_id: resp.order_id,
        email: photoLab.email,
        idle_util: estimated.idle_util,
        photo_type: store.photoType
      })

      store.order = resp

      setLoading(false)
      history.push({
        pathname: "review"
      })
    } catch (e) {
      // if (e.response) {
      //   if (e.response.data) {
      //     setError(e.response.data)
      //     return
      //   }
      // }
      setLoading(false)
      setError(JSON.stringify(e.response))
    }
  }, [
    t,
    photos,
    photoLab,
    estimated,
    remarks,
    getPath,
    history
  ])

  const onConfirmPromotion = useCallback(
    async value => {
      try {
        setShowPromotionCode(false)

        setLoading(true)
        setPromotionCode(value.toUpperCase())
        const estimated = await estimate(
          photoLab,
          value.toUpperCase()
        )

        if (estimated.promo_error_message) {
          setLoading(false)
          setError(estimated.promo_error_message)
          return
        }

        setEstimated(estimated)
      } catch (e) {
        setError(e.message)
      } finally {
        setLoading(false)
      }
    },
    [estimate, photoLab]
  )

  return (
    <div className={"container"}>
      <Header />
      <Process step={2} />

      <div className={"scrollable"}>
        <div className={"card"}>
          <div className={"card__header"}>
            <h3>{t("text.delivery_to")}</h3>
            <span
              onClick={() => {
                history.goBack()
              }}
            >
              {t("text.change_address")}
            </span>
          </div>

          <div className={"card__content"}>
            <div className={"icon"}>
              <MarkerIcon />
            </div>
            <div className={"card__content__address"}>
              <span>{store.path.address}</span>
              <br />
              <span
                onClick={() => {
                  setShowAddAddrDetail(true)
                }}
              >
                {!!remarks ? remarks : t("text.add_address_details")}
              </span>
            </div>
          </div>
        </div>

        <div className={"card1"}>
          <div className={"card1__header"}>
            <h3>{t("text.estimated_delivery_time")}</h3>
            <span>{formattedTime}</span>
          </div>
        </div>

        <div className={"card1"}>
          <div className={"card1__header"}>
            <h3>{t("text.estimated_fee")}</h3>
          </div>

          {
            estimated.photos.map(photo => (
              <CardItem
                key={photo.id}
                label={photo.label}
                price={photo.price}
                numPhotos={photo.totalImages}
              />
            ))
          }
          <CardItem
            label={t("text.delivery_fee")}
            price={estimated.delivery_fee}
          />

          {estimated.discount !== 0 && (
            <CardItem label={t("text.discount")} price={estimated.discount} />
          )}

          <div className={"card1__footer"}>
            <span>{t("text.sub_total")}</span>
            <span>đ{numberFormat.format(estimated.estimated_fee)}</span>
          </div>
        </div>
      </div>

      <div className={"actions"}>
        <div className={"actions__fixed"}>
          <Ripples onClick={() => setShowPromotionCode(true)}>
            <div className={"actions__top"}>
              <span>
                {promotionCode
                  ? t("text.your_promotion_code_is", {
                      promotionCode
                    })
                  : t("button.add_promotion_code")}
              </span>
            </div>
          </Ripples>
          <div className={"actions__bottom"}>
            <div className={"actions__item"}>
              <div className={"actions__item__text"}>
                <span className={"actions__item__payment"}>
                  {t("text.payment_method")}
                </span>
                <div className={"actions__item__payment-value"}>
                  <CashIcon />
                  <span>{t("text.cash")}</span>
                </div>
              </div>
            </div>

            <div className={"actions__item"}>
              <Button
                disabled={disabled}
                title={t("button.book")}
                onClick={onBook}
              />
            </div>
          </div>
        </div>
      </div>

      <Alert
        visible={!!error}
        buttonTitle={t(shouldGoBack ? "button.go_back" : "button.close")}
        title={t("text.sth_went_wrong")}
        message={error}
        onClick={() => {
          setError("")

          if (shouldGoBack) {
            history.goBack()
          }
        }}
      />

      <Loading visible={loading} />

      <ConfirmAlert
        visible={showAddAddrDetail}
        value={remarks}
        showInput={true}
        title={t("text.address_details")}
        message={t("text.address_details_message")}
        placeholder={t("placeholder.house_num_alley_building")}
        onClose={() => {
          setShowAddAddrDetail(false)
        }}
        onConfirm={value => {
          setRemarks(value)
          setShowAddAddrDetail(false)
        }}
      />

      <ConfirmAlert
        visible={showPromotionCode}
        value={promotionCode.toUpperCase()}
        showInput={true}
        upperCase={true}
        title={t("text.promotion_code")}
        message={t("text.promotion_code_message")}
        placeholder={t("placeholder.promotion_code")}
        onClose={() => {
          setShowPromotionCode(false)
        }}
        onConfirm={onConfirmPromotion}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          display flex
          flex-direction column
          background #F2F2F2
          height 100vh
        }
        
        .scrollable {
          min-height 350px
          overflow-y scroll
          margin-bottom 4px
        }

        .card {
          padding 16px
          background #FFF
          font-size 14px
          
          &__header {
            display flex
            justify-content space-between
            
            h3 {
              font-weight 500
              line-height 20px
            }
            
            span {
              color #FF8200
            }
          }
          
          &__content {
            display flex
            margin-top 21px
            margin-left 16px
            
            &__address {
              margin-left 16px
              line-height 18px
              
              span:last-child {
                color #7A8189
              }
            }
          }
        }
        
        .card1 {
          padding 18px
          margin-top 10px
          background #FFF
          font-size 14px
          
          &__header {
            display flex
            justify-content space-between
            
            h3 {
              font-weight 500
            }
            
            span {
              color #7A8189
            }
          }
          
          &__footer {
            display flex
            justify-content space-between
            padding 12px 0
            margin-top 12px
            border-top 1px solid #F2F2F2
            
            span:first-child {
              font-weight 500
            }
            
            span:last-child {
              color #FF8200
            }
          }
        }
        
        .actions {
          flex 1
          min-height 120px
          border-radius 4px
          transform translate3d(0,0,0)
          
          &__top {
            display flex
            width 100%
            padding 16px 0
            color #7a8189
            cursor pointer
          }
          
          &__bottom {
            display flex
            width 100%
          }
        
          &__fixed {
            display flex
            //padding 16px
            background #FFF
            align-items center
            padding 0 16px 16px 16px
            position fixed
            flex-direction column
            //height 94px
            bottom 0
            left 0
            right 0
          }
          
          &__item {
            flex 1
            
            &__text {
              display flex
              justify-content center
              flex-direction column
              align-items center
              width fit-content
            }
            
            &__payment {
              font-size 14px
              font-weight 500
              color #1A1A1A
              line-height 20px
            }
            
            &__payment-value {
              margin-top 10px
              display flex
              align-items center
              
              span {
                margin-left 8px
                font-size 14px
              }
            }
          }
        }
      `}</style>
    </div>
  )
}

import React, { useMemo } from "react"
import { Header2 } from "../components/Header2"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-use"
import { CancellationIcon, RejectIcon, SuccessIcon } from "../assets"
import { Status } from "../interfaces"

export const StatusPage = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const status = location.state.state.status
  const msg = location.state.state.message

  const Icon = useMemo(() => {
    switch (status) {
      case Status.Cancelled:
        return <CancellationIcon />
      case Status.Succeed:
        return <SuccessIcon />
      case Status.Rejected:
        return <RejectIcon />
      default:
        return <RejectIcon />
    }
  }, [status])

  const title = useMemo(() => {
    switch (status) {
      case Status.Cancelled:
        return t("status.your_order_has_been_cancelled")
      case Status.Succeed:
        return t("status.confirm_order_successfully")
      case Status.Rejected:
        return t("status.reject_order_successfully")
      default:
        return t("status.sth_went_wrong")
    }
  }, [status, t])

  const message = useMemo(() => {
    switch (status) {
      case Status.Cancelled:
        return t("status.your_order_has_been_cancelled_message")
      case Status.Succeed:
        return t("status.confirm_order_successfully_message")
      case Status.Rejected:
        return t("status.reject_order_successfully_message")
      case 1100:
        return t('text.order_doesnt_exist')
      default:
        return JSON.stringify(msg)
    }
  }, [status, msg, t])

  return (
    <div className={"container"}>
      <Header2 title={t("app.confirmation_page")} />

      <div className={"content"}>
        {/*<span>{parsedQuery.message ?? "404 Page not found"}</span>*/}

        {/*<Button*/}
        {/*  title={t('button.go_home')}*/}
        {/*  onClick={() => {*/}
        {/*    history.replace("/")*/}
        {/*  }}*/}
        {/*/>*/}
        {Icon}
        <span className={"title"}>{title}</span>
        <span className={"message"}>{message}</span>
      </div>

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          background #F4F5F7
          display flex
          justify-content center
          min-height 100vh
        }
        
        .content {
          display flex
          align-items center
          justify-content center
          flex-direction column
          background #FFF
          text-align center
          margin 97px 28px 28px
          //height 620px
          //padding 60px
          width 100%
          height fit-content
          font-size 20px
          padding 60px 24px 24px
          
          @media only screen and (max-width 830px) {
            flex-direction column
            align-items center
            width 100%
            padding 16px
          }
          
          > span {
            margin-bottom 16px
          }
        }
        
        .title {
          font-size 24px
          margin 16px
          font-weight 500
        }
        
        .message {
          line-height 20px
        }
      `}</style>
    </div>
  )
}

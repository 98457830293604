import React, { useState } from "react"
import axios from "axios"
import { Header } from "../components/Header"
import { Process } from "../components/Process"
import { MarkerIcon } from "../assets"
import { useDebounce } from "react-use"
import { BoxInput } from "../components/BoxInput"
import { useHistory } from "react-router-dom"
import { Alert } from "../components/Alert"
import { useTranslation } from "react-i18next"
import store from "../store"

export type SuggestionItemProps<T = Feature> = {
  onClick(value: T): void
  value: T
}

export const SuggestionItem: React.FC<SuggestionItemProps> = ({
  value,
  onClick
}) => {
  return (
    <div className={"container"} onClick={() => onClick(value)}>
      <div className={"icon"}>
        <MarkerIcon />
      </div>

      <div className={"content"}>
        <span>{value.properties.name}</span>
      </div>

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          height 72px
          display flex
          background #FFF
          border-bottom 2px solid #F2F2F2
          align-items center
        }
        
        .content {
          display flex
          flex-direction column
          justify-content center
          padding-right 16px
          
          span {
            &:first-child {
              font-size 16px
              line-height 24px
              color #1A1A1A
            }
            
            &:last-child {
              font-size 14px
              color #7A8189
            }
          }
        }
        
        .icon {
          padding 16px
        }
      `}</style>
    </div>
  )
}
export interface Geometry {
  type: string
  coordinates: number[]
}

export interface Properties {
  source: string
  name: string
}

export interface Feature {
  type: string
  geometry: Geometry
  properties: Properties
}

export interface AutocompleteData {
  source_list: string[]
  features: Feature[]
}

export const SearchAddressPage = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const [error, setError] = useState("")
  const [address, setAddress] = useState("Ahamove")
  const [suggestions, setSuggestions] = useState<Feature[]>([])

  useDebounce(
    async () => {
      try {
        if (!address) {
          setSuggestions([])
          return
        }

        const data = await axios
          .get<AutocompleteData>(process.env.REACT_APP_AUTOCOMPLETE_URL!, {
            params: {
              api_key: process.env.REACT_APP_AUTOCOMPLETE_API_KEY,
              text: address
            }
          })
          .then(result => result.data)

        setSuggestions(data.features)
      } catch (e) {
        setError(e.message)
      }
    },
    600,
    [address]
  )

  return (
    <div className={"container"}>
      <Header />
      <Process step={2} />

      <div className={"content"}>
        <BoxInput
          value={address}
          onChange={e => setAddress(e.target.value)}
          onClear={() => setAddress("")}
        />

        <div className={"suggestion"}>
          {suggestions.map(v => (
            <SuggestionItem
              key={v.properties.name}
              value={v}
              onClick={v => {
                const address = v.properties.name
                const [lng, lat] = v.geometry.coordinates

                store.path = {
                  address,
                  lat,
                  lng
                }

                history.push({
                  pathname: "checkout"
                })
              }}
            />
          ))}
        </div>
      </div>

      <Alert
        visible={!!error}
        buttonTitle={t("button.go_back")}
        message={error}
        title={t("text.sth_went_wrong")}
        onClick={() => {
          history.goBack()
        }}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          display flex
          flex-direction column
          background #F2F2F2
          height 100%
        }

        .content {
          padding 16px
        }
        
        .suggestion {
          //margin-top 16px
          position fixed
          overflow-y scroll
          top 197px
          left 16px
          right 16px
          bottom 0
        }
      `}</style>
    </div>
  )
}

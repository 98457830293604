import React from "react"
import { CloseIcon } from "../assets"
import ReactCodeInput from "react-code-input"
import { useLang } from "../hooks/useLang"

export const OTPPage = () => {
  useLang()
  return (
    <div className={"container"}>
      <div className={"header"}>
        <div className={"icon"}>
          <CloseIcon fill={"red"} />
        </div>
        <h3>Change phone number</h3>
      </div>

      <span className={"message"}>
        Check your message, we've just sent an OTP to{" "}
        <span className={"phone_number"}>+84932623079</span>
      </span>

      <div className={"content"}>
        <span className={"content__title"}>My OTP is</span>
        <ReactCodeInput
          type="number"
          fields={6}
          className={"content__code-input"}
        />
      </div>

      <div className={"footer"}>
        <span>Didn't get the code?</span>
        <span>
          Request OTP again after <span className={"footer__time"}>59s</span>
        </span>
      </div>

      {/*language=Stylus*/}
      <style global jsx>{`
        .content__code-input {
          input {
            height 40px
            width 40px
            margin-right 8px
            background #F2F2F2
            border none
            text-align center
            font-size 20px
            font-weight 500
            border-radius 2px
            
            &:focus {
              border 1px solid #FF8200
            }
          }
        }
      `}</style>

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          padding 16px
        }
        
        .header {
          display flex
          font-size 16px
          color #FF3B30
          font-weight 500
          margin-top 44px
          
          h3 {
            margin-left 8px
          }
        }
        
        .message {
          margin-top 24px
          display block
          font-size 16px
          color #7A8189
          line-height 24px
          padding-right 16px
          
          .phone_number {
            color #1A1A1A
            font-weight 500
          }
        }
        
        .content {
          margin-top 16px
          display flex
          flex-direction column
          
          &__title {
            font-size 16px
            font-weight 500
            margin-bottom 8px
          }
        }
        
        .footer {
          margin-top 32px
          display flex
          flex-direction column
          font-size 16px
          line-height 24px
          
          span:first-child {
            color #BCBCBC
          }
          
          span:last-child {
            color #7A8189
          }
          
          &__time {
            color #FF8200 !important
          }
        }
      `}</style>
    </div>
  )
}

import React from "react"
import { Header } from "../components/Header"
import { useTranslation } from "react-i18next"
import { Button } from "../components/Button"
import { useHistory } from "react-router-dom"

export type SectionProps = {
  title: string
  content: string
}

export const Section: React.FC<SectionProps> = ({ title, content }) => {
  return (
    <div className={"container"}>
      <strong className={"title"}>{title}</strong>
      <span className={"content"}>{content}</span>

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          padding 16px 16px 0
        }
        
        .title {
          display block
          font-size 16px
          font-weight 500
          line-height 22px
          margin-bottom 8px
          color #FF8200
        }
        
        .content {
          display inline-block
          font-size 14px
          line-height 20px
        }
      `}</style>
    </div>
  )
}

export const InstructionPage = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className={"container"}>
      <Header title={'Read Photo’s guide first'}/>

      <div>
        <Section title={t("guide.note")} content={t("guide.note_message")} />
        <Section
          title={t("guide.how_to_take")}
          content={t("guide.how_to_take_message")}
        />
        <Section
          title={t("guide.equipment")}
          content={t("guide.equipment_message")}
        />

        <Section
          title={t("guide.background")}
          content={t("guide.background_message")}
        />
        <Section
          title={t("guide.lighting_flash")}
          content={t("guide.lighting_flash_message")}
        />
        <Section
          title={t("guide.clothes")}
          content={t("guide.clothes_message")}
        />
        <Section
          title={t("guide.glasses")}
          content={t("guide.glasses_message")}
        />
        <Section title={t("guide.hair")} content={t("guide.hair_message")} />
        <Section
          title={t("guide.distance")}
          content={t("guide.distance_message")}
        />
        <Section title={t("guide.face")} content={t("guide.face_message")} />
        <Section
          title={t("guide.take_few_short_without_flash")}
          content={t("guide.take_few_short_without_flash_message")}
        />
        <Section
          title={t("guide.do_not_edit")}
          content={t("guide.do_not_edit_message")}
        />
        <Section title={t("guide.bad_source_photo_examples")} content={""} />
        <img
          style={{ width: "100%" }}
          src="https://storage.googleapis.com/aha-photolab-assets/kao/visa-error-preview.png"
          alt=""
        />
      </div>

      <Button
        padding={16}
        title={t("button.understand_countinues")}
        onClick={() => {
          history.push({
            pathname: "camera",
          })
        }}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          padding-bottom 16px
        }
      `}</style>
    </div>
  )
}

class Store {
  lang = ""
  token = ""
  photoType = "photo"
  files: File[] = []
  photos: any[] = []
  path: any = {}
  order: any = {}

  get hasFiles() {
    return this.files.length !== 0
  }

  get visaImageUrl() {
    const photo = this.photos[0]

    return `https://storage.googleapis.com/aha-photolab/${process.env.REACT_APP_STAGE}/${photo.filename}.jpeg`
  }
}

const store = new Store()

window["store"] = store

export default store

import React, { useCallback, useEffect, useRef, useState } from "react"
import { Process } from "../components/Process"
import { CloseIcon, PencilIcon } from "../assets"
import { Button } from "../components/Button"
import { Divider } from "./CameraPage"
import { useTranslation } from "react-i18next"
import { EditModal } from "../components/EditModal"
import { Photo } from "../interfaces"
import { useHistory } from "react-router-dom"
import { useAsync } from "react-use"
import { toBase64, urlToFile } from "../functions/file-extra"
import { nanoid } from "nanoid"
import { ActionFixed } from "../components/ActionFixed"
import store from "../store"
import { Alert } from "../components/Alert"

type PhotoPreviewProps = Photo & {
  onEdit(): void
  onRemove(id: string): void
}

const PhotoPreview: React.FC<PhotoPreviewProps> = ({
  id,
  totalImages,
  imageUrl,
  onEdit,
  onRemove,
  label
}) => {
  return (
    <div className={"photo"}>
      <div className={"photo__icon"} onClick={() => onRemove(id)}>
        <CloseIcon />
      </div>
      <div className={"photo__nums"}>x{totalImages}</div>
      <div className={"photo__edit"} onClick={onEdit}>
        <PencilIcon />
      </div>
      <img src={imageUrl} alt={""} />
      <span>{label}</span>

      {/*language=Stylus*/}
      <style jsx>{`
        .photo {
          width 100%
          border 1px solid #1A1A1A
          position relative
          
          &__icon {
            position absolute
            top -15px
            left -15px
          }
          
          &__nums {
            position absolute
            top 0
            right 0
            padding 4px 6px
            font-size 20px
            background #FF8200
            color white
            border-bottom-left-radius 8px
          }
          
          &__edit {
            position absolute
            right 0
            bottom 24px
            padding 4px 6px
            background #142246
            opacity 0.8
            color white
            border-top-left-radius 8px
          }
          
          img {
            width 100%
            height calc(100vw / 2)
            object-fit cover
          }
          
          span {
            display block
            height 24px
            line-height 20px
            text-align center
          }
        }
      `}</style>
    </div>
  )
}

export const ProcessPhotosPage = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const inputRef = useRef<any>(null)

  const [showEdit, setShowEdit] = useState(false)
  const [currentPhoto, setCurrentPhoto] = useState<Photo | null>(null)
  const [photos, setPhotos] = useState<Photo[]>([])
  const [error, setError] = useState("")

  const onUpdatePhotos = useCallback(
    (photo: Photo) => {
      const index = photos.findIndex(o => o.id === photo.id)
      setPhotos(photos => Object.assign([], photos, { [index]: photo }))
    },
    [photos]
  )

  const onRemovePhotos = useCallback((id: string) => {
    setPhotos(photos => photos.filter(o => o.id !== id))
  }, [])

  const onUploadedFiles = useCallback(
    async e => {
      const files: FileList = e.target.files

      if (files) {
        const filesArray = Array.from(files)

        let hasError = false
        filesArray.forEach(file => {
          if (file.size > 2_000_000) {
            setError(t("text.file_exceed_maximum"))
            hasError = true
            return
          }
        })

        if (hasError) {
          return
        }

        const urls = await Promise.all(filesArray.map(toBase64))
        const createdPhotos: Photo[] = urls.map((u, i) => ({
          id: nanoid(10),
          totalImages: 1,
          imageUrl: u,
          file: files[i],
          filename: "",
          price: 0,
          label: "6x9 cm",
          printingSize: {
            size: "6x9",
            unit: "cm"
          }
        }))
        const newPhotos = photos.concat(createdPhotos)
        setPhotos(newPhotos)
      }
    },
    [photos, t]
  )

  useEffect(() => {
    store.photos = photos
  }, [photos])

  useAsync(async () => {
    const cachedPhotos: Photo[] = JSON.parse(
      localStorage.getItem("photos") ?? ""
    )
    const files = await Promise.all(
      cachedPhotos.map(photo =>
        urlToFile(photo.imageUrl, photo.id, "image/png")
      )
    )
    setPhotos(
      cachedPhotos.map((photo, index) => ({
        ...photo,
        file: files[index]
      }))
    )
  }, [])

  useEffect(() => {
    localStorage.setItem("photos", JSON.stringify(photos))
  }, [photos])

  return (
    <div>
      <Process />
      <h2 className={"title"}>{t("text.photo")}</h2>
      <span className={"subtitle"}>{t("text.normal_photo_subtitle")}</span>

      <div className={"photos"}>
        {photos.map(photo => (
          <PhotoPreview
            key={photo.id}
            {...photo}
            onEdit={() => {
              setCurrentPhoto(photo)
              setShowEdit(true)
            }}
            onRemove={onRemovePhotos}
          />
        ))}
      </div>

      <input
        accept="image/*"
        type="file"
        id={"select-file"}
        onChange={onUploadedFiles}
        multiple
        style={{ display: "none" }}
        ref={inputRef}
      />

      <ActionFixed fixed={false}>
        <Button
          background={"#F2F2F2"}
          color={"#142246"}
          title={t("button.add_photo")}
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.click()
            }
          }}
        />
        <Divider width={15} />
        <Button
          title={t("button.continue_confirm")}
          disabled={photos.length === 0}
          onClick={() => {
            history.push({
              pathname: "preview-photos"
            })
          }}
        />
      </ActionFixed>

      <Alert
        visible={!!error}
        title={t("text.your_order_has_been_cancelled")}
        message={error}
        buttonTitle={t("button.i_got_it")}
        onClick={() => {
          setError("")
        }}
      />

      {currentPhoto && (
        <EditModal
          visible={showEdit}
          data={currentPhoto}
          onClose={() => {
            setShowEdit(false)
          }}
          onUpdate={data => {
            onUpdatePhotos(data)
            setShowEdit(false)
          }}
        />
      )}

      {/*language=Stylus*/}
      <style jsx>{`
        .title {
          font-size 16px
          font-weight 500
          margin-top 12px
          margin-bottom 4px
          text-align center
        }
        
        .subtitle {
          font-size 12px
          line-height 16px
          margin 0 32px
          display block
          text-align center
        }
        
        .photos {
          display grid
          grid-gap 24px
          grid-template-columns 1fr 1fr
          margin 16px
          padding-bottom 100px
        }
      `}</style>
    </div>
  )
}


export interface Path {
  address: string
  lat: number
  lng: number
  remarks: string
  cod?: number
  cod_discrepancy?: number
  visa_photo_url: string
  total_photos: number
}

export interface FromLocation {
  type: string
  coordinates: number[]
}

export interface Service {
  _id: string
  enable: boolean
  city_id: string
  name: string
  name_vi_vn: string
  icon_url: string
  map_icon_url: string
  name_en_us: string
  require_to: boolean
  parent_id: string
  uniform_verify: boolean
  uniform_verify_message_vi_vn: string
  uniform_verify_message_en_us: string
  delivery_type: string
}

enum OrderStatus {
  Confirming = "CONFIRMING",
  Idle = "IDLE",
  Assigned = "ASSIGNED",
  Cancelled = "CANCELLED"
}

export interface PhotoOrder {
  total_images: number,
  image_url: string
  price: number
  visa_type?: string
  size: string
  unit: string
}

export interface PhotoLabOrder {
  _id: string
  need_edit: boolean
  photo_type: string
  service_id: string
  merchant_id?: string
  created_at: number
  status: OrderStatus
  photos?: PhotoOrder[]
}

export interface Order {
  _id: string
  currency: string
  user_main_account: number
  user_bonus_account: number
  total_pay: number
  distance: number
  duration: number
  distance_fee: number
  request_fee: number
  stop_fee: number
  vat_fee: number
  discount: number
  path: Path[]
  from_location: FromLocation
  requests: any[]
  items: any[]
  total_fee: number
  app: string
  service_id: string
  city_id: string
  user_id: string
  user_name: string
  user_imei?: any
  create_time: number
  order_time: number
  delay_until: number
  index: number
  status: string
  partner: string
  idle_until: number
  polylines: string
  online_pay: number
  notify_package_return: boolean
  uniform_user_feedback: number
  pending_period: number
  payment_method: string
  timeout: number
  user_referral_code: string
  stoppoint_price: number
  special_request_price: number
  vat: number
  distance_price: number
  voucher_discount: number
  subtotal_price: number
  total_price: number
  service: Service
  uniform_verify: boolean
  uniform_verify_message_en_us: string
  uniform_verify_message_vi_vn: string
  extra: PhotoLabOrder
}

export enum Status {
  Cancelled,
  Succeed,
  Rejected
}

export interface Photo {
  id: string
  label: string
  totalImages: number
  imageUrl: string
  price: number
  filename: string
  visaType?: string
  file: File
  printingSize?: {
    size: string
    unit: string
  }
}

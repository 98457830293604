import React, { useCallback, useEffect, useState } from "react"
import { useAsync } from "react-use"
import { useHistory } from "react-router-dom"
import { Button } from "../components/Button"
import { Divider } from "./CameraPage"
import { useTranslation } from "react-i18next"
import axios from "axios"
import dayjs from "dayjs"
import { CancelIcon, CashIcon, ConfirmIcon } from "../assets"
import { numberFormat } from "../functions/format"
import { ConfirmAlert } from "../components/ConfirmAlert"
import { Alert } from "../components/Alert"
import { Order, PhotoOrder, Status } from "../interfaces"
import { LineLoading } from "../components/LineLoading"
import { ImageLoading } from "../components/ImageLoading"
import { CheckboxButton } from "../components/CheckboxButton"
import { Header2 } from "../components/Header2"
import { CancelData, CancelPopup } from "../components/CancelPopup"
import { Loading } from "../components/Loading"
import { useQueryString } from "../hooks/useQueryString"

const isObjectEmpty = obj => Object.keys(obj).length === 0 && obj.constructor === Object

export const ConfirmPage = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const qs = useQueryString()

  const [order, setOrder] = useState<Order | null>(null)
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState<any>("")
  const [isCancel, setIsCancel] = useState(false)
  const [shouldConfirm, setShouldConfirm] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false)
  const [needEdit, setNeedEdit] = useState(false)
  const [reason, setReason] = useState("")
  const [totalPhotos, setTotalPhotos] = useState(0)
  const [photos, setPhotos] = useState<PhotoOrder[]>([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [code, setCode] = useState(-1)

  useAsync(async () => {
    await i18n.changeLanguage("vi")
  }, [])

  useAsync(async () => {
    try {
      const data = await axios
        .get<Order & any>(`${process.env.REACT_APP_PHOTOLAB_URL}/order-details`, {
          params: {
            order_id: qs.order_id
          }
        })
        .then(resp => resp.data)

      if (isObjectEmpty(data) || data.code === 1100) {
        setError(t('text.order_doesnt_exist'))
        return
      }

      if (data.code) {
        setCode(data.code)
        setError(data.message)
        return
      }

      setOrder(data)
      setTotalPrice(data.path[1].cod ?? 0)

      if (data?.extra) {
        const photos = data.extra.photos
        if (photos) {
          setPhotos(photos)
          const totalPhotos = photos.reduce(
            (acc, cur) => acc + cur.total_images,
            0
          )
          setTotalPhotos(totalPhotos)
        }
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      if (e.response) {
        const respData = e.response.data
        if (respData.message) {
          setCode(respData.code)
          setError(respData.message)
        } else {
          setError(respData)
        }
        return
      }

      if (e.message === 'Network Error') {
        setError(t('text.system_has_an_error'))
        return
      }

      setError(e.message)
    }
  }, [])

  const onCancel = useCallback(
    async ({ reason }: CancelData) => {
      try {
        setSubmitLoading(true)
        await axios.post(`${process.env.REACT_APP_PHOTOLAB_URL}/cancel-order`, {
          order_id: qs.order_id,
          comment: reason
        })

        setSubmitLoading(false)
        setIsCancelled(true)
      } catch (e) {
        setSubmitLoading(false)
        setError(e.message)
      }
    },
    [qs.order_id]
  )

  const onConfirm = useCallback(async () => {
    try {
      setShouldConfirm(true)
      setSubmitLoading(true)
      setShouldConfirm(false)

      if (!order) {
        throw new Error(t('text.cant_find_order'))
      }

      const url = `https://photolab-downloader.ahamove.net/${process.env.REACT_APP_STAGE}/download-zip?order_id=${order._id}`
      const win = window.open(url, "_blank")
      win?.focus()

      await axios.post(`${process.env.REACT_APP_PHOTOLAB_URL}/confirm-order`, {
        order_id: qs.order_id,
        need_edit: needEdit,
        service_id: order.extra.service_id
      })

      setSubmitLoading(false)
      history.push({
        pathname: "status",
        state: {
          status: Status.Succeed
        }
      })
    } catch (e) {
      setSubmitLoading(false)
      setError(e.message)
    }
  }, [needEdit, t, history, order, qs.order_id])

  useEffect(() => {
    if (error) {
      history.push({
        pathname: "status",
        state: {
          status: code ? code : Status.Cancelled,
          message: error
        }
      })
    }
  }, [error, history, code])

  //207P4Z0T
  return (
    <div className={"container"}>
      <Header2 title={t("app.confirmation_page")} />

      <div className={"content"}>
        <div className={"content__left"}>
          {photos.length > 0 ? (
            photos.map((photo, index) => (
              <div className={"photo"} key={`photo-${index}`}>
                <img
                  src={photo.image_url}
                  style={{ width: photo.visa_type ? "337px" : "100%" }}
                  alt=""
                />
                <div className={"photo__details"}>
                  <span className={"photo__details__title"}>Total</span>
                  <span>{photo.total_images}</span>

                  {photo.visa_type && (
                    <>
                      <span className={"photo__details__title"}>Visa Type</span>
                      <span>{photo.visa_type}</span>
                    </>
                  )}
                  {photo.size && (
                    <>
                      <span className={"photo__details__title"}>Size</span>
                      <span>
                        {photo.size} {photo.unit}
                      </span>
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <ImageLoading />
          )}
        </div>

        <div className={"content__right"}>
          <h3 className={"content__title"}>{t("text.confirm_this_order")}</h3>

          {!!order ? (
            <div className={"content__info"}>
              <span className={"content__info__title"}>
                {t("text.order_id")}
              </span>
              <span className={"content__info__text"}>#{order._id}</span>

              <span className={"content__info__title"}>
                {t("text.date_created")}
              </span>
              <span className={"content__info__text"}>
                {dayjs.unix(order.create_time).format("DD/MM/YYYY - hh:mm")}
              </span>

              <span className={"content__info__title"}>
                {t("text.photo_type")}
              </span>
              <span className={"content__info__text"}>
                {order.extra.photo_type}
              </span>

              <span className={"content__info__title"}>
                {t("text.total_photos")}
              </span>
              <span className={"content__info__text"}>{totalPhotos}</span>

              <span className={"content__info__title"}>
                {t("text.total_fee")}
              </span>
              <span className={"content__info__text"}>
                <CashIcon />{" "}
                <span className={"pricing"}>
                  đ{numberFormat.format(totalPrice)}
                </span>
              </span>

              <span className={"content__info__title"}>
                {t("text.photo_need_edit")}
              </span>
              <span className={"content__info__text"}>
                <CheckboxButton
                  checked={needEdit}
                  onChecked={() => setNeedEdit(!needEdit)}
                />
              </span>
            </div>
          ) : (
            <LineLoading />
          )}

          <div className={"actions"}>
            <Button
              icon={<ConfirmIcon />}
              disabled={loading}
              // width={"187px"}
              color={"#FFF"}
              background={"#FF8200"}
              title={t("button.confirm")}
              onClick={() => {
                setShouldConfirm(true)
              }}
            />
            <Divider width={15} />
            <Button
              icon={<CancelIcon />}
              disabled={loading}
              // width={"187px"}
              background={"rgba(255, 59, 48, 0.1)"}
              color={"#FF3B30"}
              title={t("button.cancel")}
              onClick={() => {
                setIsCancel(true)
              }}
            />
          </div>
        </div>
      </div>

      <CancelPopup
        visible={isCancel}
        onClose={() => setIsCancel(false)}
        onSubmit={async data => {
          setIsCancel(false)
          setReason(data.reason)
          await onCancel(data)
        }}
      />

      <Loading visible={submitLoading} />

      <ConfirmAlert
        visible={shouldConfirm}
        title={t("text.confirm_your_order")}
        message={t("text.pls_click_yes_to_confirm_order")}
        onClose={() => {
          setShouldConfirm(false)
        }}
        onConfirm={onConfirm}
      />

      <Alert
        visible={isCancelled}
        title={t("text.your_order_has_been_cancelled")}
        message={reason}
        buttonTitle={t("button.i_got_it")}
        onClick={() => {
          setIsCancelled(false)

          history.push({
            pathname: "status",
            state: {
              status: Status.Rejected
            }
          })
        }}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          background #F4F5F7
          display flex
          justify-content center
          min-height 100vh
        }
        
        .photo {
          img {
            width 100%
          }
          
          &__details {
            display grid
            grid-template-columns 80px 1fr
            grid-row-gap 8px
            padding 16px
            background #F2F2F2
            margin-bottom 16px
            font-size 16px
            
            &__title {
              font-weight 500
            }
          }
        }

        .content {
          display flex
          align-items flex-start
          background #FFF
          margin 97px 60px 60px
          //height 620px
          padding 60px
          width fit-content
          height fit-content
          
          @media only screen and (max-width 830px) {
            flex-direction column
            align-items center
            width 100%
            padding 16px
          }
          
          &__left {
            //img {
            //  height 449px
            //  width 337px
            //  margin-bottom 16px
            //  object-fit contain
            //}
          }
          
          &__right {
            height 100%
            margin-left 60px
            
            @media only screen and (max-width 830px) {
              margin-top 32px
              margin-left 0
              width 100%
            }
          }
          
          &__info { 
            display grid
            grid-template-columns: 200px 200px
            grid-template-rows repeat(6, 44px)
            
            @media only screen and (max-width 830px) {
              grid-template-columns: 1fr 1fr
            }
            
            &__title {
              font-weight 500
              display flex
              align-items center
            }
            
            &__text {
              display flex
              align-items center
              
              .pricing {
                color #FF8200
                display inline-block
                margin-left 8px
              }
            }
          }
          
          &__title {
            font-size 24px
            font-weight 500
            line-height 36px
          }
          
          &__subtitle {
            display inline-block
            font-size 12px
            line-height 20px
            color #7A8189
            margin-bottom 21px
          }
        }
        
        .preview {
          width 200px
          //padding 8px
          background #EAF0FF
        }

        .actions {
          display flex
          //margin-top 40px
        }
      `}</style>
    </div>
  )
}

import React, { useCallback, useMemo, useState } from "react"
import { Process } from "../components/Process"
import { useHistory } from "react-router-dom"
import { Button } from "../components/Button"
import { useTranslation } from "react-i18next"
import { Photo } from "../interfaces"
import axios from "axios"
import { Loading } from "../components/Loading"
import { Alert } from "../components/Alert"
import store from "../store"

export const PreviewPhotosPage = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const photos: Photo[] = store.photos

  const totalPhotos = useMemo(() => {
    return photos.reduce((acc, cur) => acc + cur.totalImages, 0)
  }, [photos])

  const onConfirm = useCallback(async () => {
    try {
      setLoading(true)
      const formData = new FormData()
      photos.forEach(photo => {
        formData.append(photo.id, photo.file)
      })

      const result = await axios
        .post(`${process.env.REACT_APP_PHOTOLAB_URL}/upload-files`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(resp => resp.data)

      localStorage.clear()
      store.photos = photos.map((photo, index) => ({
        ...photo,
        filename: result[index],
        imageUrl: undefined,
        file: undefined
      }))

      setLoading(false)

      history.push({
        pathname: "delivery"
      })
    } catch (e) {
      setLoading(false)
      if (e.response) {
        setError(JSON.stringify(e.response.data))
        return
      }
      setError(e.message)
    }
  }, [photos, history])

  return (
    <div className={"container"}>
      <Process step={1} />
      <h2 className={"title"}>Xác nhận kích thước và số lượng ảnh</h2>

      {photos.map(photo => (
        <div key={photo.id} className={"section"}>
          <span>Photo {photo.label}</span>
          <span className={"section__num-photos"}>{photo.totalImages} ảnh</span>
        </div>
      ))}
      <div className={"section"}>
        <span>Total number</span>
        <span className={"section__num-photos"}>{totalPhotos} ảnh</span>
      </div>

      <Button absolute title={t("button.confirm")} onClick={onConfirm} />

      <Alert
        visible={!!error}
        buttonTitle={t("button.close")}
        title={t("text.sth_went_wrong")}
        message={error}
        onClick={() => {
          setError("")
        }}
      />

      <Loading visible={loading} />

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
        
        }

        .title {
          padding 16px
          font-size 14px
          font-weight 500
        }
        
        .section {
          padding 16px 0
          margin 0 16px
          border-top 1px solid #F2F2F2
          display flex
          justify-content space-between
          
          &__num-photos {
            font-weight 500
          }
        }
      `}</style>
    </div>
  )
}
